(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/common/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/common/assets/javascripts/constants.js');
"use strict";


const MenuTabTypes = {
  Play: 'spela',
  MyBets: 'mina-spel',
  GameGuide: 'spelguide',
  Widget: 'widgetyta'
};
const widgetAreaPrefix = 'oddsetWidgetArea';
const KambiWidgetEvents = {
  SET_BETRADAR_ID: 'setBetradarId'
};
setGlobal('svs.oddset_kambi.components.common.constants', {
  MenuTabTypes,
  widgetAreaPrefix,
  KambiWidgetEvents
});

 })(window);