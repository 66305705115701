(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/common/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/common/assets/javascripts/utils.js');
"use strict";

const {
  account_balance: accountBalance
} = svs.components;
const updateBalance = () => {
  accountBalance.get(true, balance => {
    accountBalance.set(balance);
  });
};
setGlobal('svs.oddset_kambi.components.common.utils', {
  updateBalance
});

 })(window);